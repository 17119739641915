/* Attestis color palette */
@import "_variables";

$attestis-primary: (
    50 : #e7f7f6,
    100 : #c4eae9,
    200 : #9ddcda,
    300 : #75cecb,
    400 : #58c4c0,
    500 : $at-primary,
    600 : #34b2ae,
    700 : #2caaa5,
    800 : #25a29d,
    900 : #18938d,
    A100 : #c9fffc,
    A200 : #96fffa,
    A400 : #63fff7,
    A700 : #4afff6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$attestis-accent: (
    50 : #e8e9ea,
    100 : #c5c8cb,
    200 : #9fa4a9,
    300 : #797f87,
    400 : #5c636d,
    500 : $at-black,
    600 : #39414c,
    700 : #313842,
    800 : #293039,
    900 : #1b2129,
    A100 : #70acff,
    A200 : #3d8eff,
    A400 : #0a70ff,
    A700 : #0064f0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$attestis-warn: (
    50 : #f7e3e6,
    100 : #ecbac1,
    200 : #df8c98,
    300 : #d25e6f,
    400 : #c83c50,
    500 : $at-error,
    600 : #b8162c,
    700 : #af1225,
    800 : #a70e1f,
    900 : #990813,
    A100 : #ffc6c9,
    A200 : #ff9398,
    A400 : #ff6068,
    A700 : #ff4750,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);
