@import '~@angular/material/theming';
@import '~@angular/flex-layout/mq';
// Plus imports for other components in your app.
@import '_palette';
@import '_variables';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core();
// Define the default theme (same as the example above).
$attestis-app-primary: mat-palette($attestis-primary);
$attestis-app-accent: mat-palette($attestis-accent);
$attestis-app-warn: mat-palette($attestis-warn);
$attestis-app-theme: mat-light-theme(
    $attestis-app-primary,
    $attestis-app-accent,
    $attestis-app-warn
);
// Include the default theme styles.
@include angular-material-theme($attestis-app-theme);

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    &.dark-backdrop {
        background: #000;
        opacity: 0.85 !important;
    }
}

html {
    min-height: 100%;
    height: 100%;
    display: flex;
    color: rgba(0, 0, 0, 0.87);
}

// Body
@include mat-base-typography(mat-typography-config(), 'body');

body {
    flex: 1;
    background-color: #fafafa;
}

html,
body {
    margin: 0;
    padding: 0;
}

a {
    color: $at-primary;
    text-decoration: none;
    font-weight: 500;

    &[disabled='true'],
    &.disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        cursor: default !important;
        text-decoration: none !important;
    }

    &:hover {
        text-decoration: underline;
    }

    &.no-underline,
    &.mat-button,
    &.mat-raised-button,
    &.mat-icon-button,
    &.mat-stroked-button,
    &.mat-flat-button,
    &.mat-fab,
    &.mat-mini-fab {
        /** Do not underline links when they are buttons*/
        text-decoration: none !important;
    }
}

// Fix to prevent iOS to zoom on input field
// If size in not bigger than 16px
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
    select,
    textarea,
    input {
        font-size: 16px !important;
    }
}

.capitalize-first-letter:first-letter {
    text-transform: uppercase;
}

mat-card {
    mat-card-content {
        margin-top: 1.25em !important;
        margin-bottom: 1.5em !important;
    }

    &.big {
        mat-card-title {
            font-size: xx-large;
            margin-top: 8px !important;
        }

        mat-card-subtitle {
            font-size: large;
            margin-bottom: 1.25em;
            margin-top: 4px !important;
        }
    }
}

.banner-delai-franc {
  background: linear-gradient(
      rgba(110, 187, 176, 0.5),
      rgba(110, 187, 176, 0.5)
    ),
  url("./assets/images/logo/volkan-olmez-aG-pvyMsbis-unsplas.webp")
  no-repeat center center;
  background-size: cover;
  height: 17vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.1rem;
}


.title-delai-franc {
  font-size: 3.3rem;
  color: white;
  text-align: center;
  font-weight: bolder;
  margin-top: 1.1rem;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.text-delai-franc {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  padding: 1rem;
  border-radius: 4px;
  margin: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: calc(100% - 2rem);
  margin-bottom: 1.1rem;
  word-wrap: break-word;
  background-color: white;

  h2, h3 {
    color: #2D4056;
    font-weight: 500;
    margin-bottom: 3.33px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;

  }

  p {
    color: #2D4056;
    text-align: justify;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }
}

.calculate-delais-francs {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  border-radius: 10px;
  margin: 0;
  width: 100%;
  margin-bottom: 1.1rem;
}

aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  position: sticky !important;
  top: 11%;
  margin-bottom: 3rem;

  section {
    .button-view-blog {
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: none;
      border: 0;
      color: rgb(58, 185, 181) !important;
      background-color: rgb(255, 255, 255);
      text-decoration: none !important;
      font-size: 17px;
      font-weight: bold;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      display: block;
      margin: 0 auto;
      padding: 8px 16px;
      text-align: center;
      width: 117px;
      margin-top: 1.11rem;
    }
  }
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}


aside:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  transform: scale(1.055);
}

.text-advertising .image-container {
  position: relative;
  width: 227px;
  height: 432px;
  background: linear-gradient(0deg, rgba(185,232,199,1) 0%, rgba(66,184,181,1) 44%);
  background-size: cover;
  display: flex;
  justify-content: center;
}

.advert-image {
  box-shadow: none;
  border: none;
  margin-top: 36px;
}

.overlay-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.1rem;
  font-weight: bold;
  width: 89%;
  margin-top: 7.07rem;
}

.overlay-text h2 {
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
  font-size: 1.11rem;
  color: whitesmoke;
  text-align: center;
}

.footer {
  text-align: center;
    font-size: 11px;
    margin-bottom: 3rem;
  a {
    font-size: 13px;
    color: black;
  }
}

@media screen and (max-width: 1200px) {

  .article {
    width: 89%;
  }

  footer {
    text-align: center;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {

  .title-delai-franc {
    line-height: 1.1  }

  .banner-delai-franc {
    margin-bottom: 0px;
  }

  main {
    flex-direction: column;
  }

  article,
  aside {
    width: 100%;
  }

  aside {
    align-items: center;
    margin-left: 0;
  }

  aside:hover {
    box-shadow: none;
    transform: scale(1.055);
  }

  aside > section {
    margin-right: 0;
  }

  .overlay-text {
    left: 50%;
  }

  .text-delai-franc, .calculate-delais-francs {
    width: -webkit-fill-available;
    margin-top: 0;
    margin-bottom: 0;
  }
}

