/** Primary color */
$at-primary: #3ab9b5;
/** Secondary color */
$at-secondary: #2aafbe;
/** Error color */
$at-error: #be1931;
/** Warn color */
$at-orange: #ffb300;
/** Black color */
$at-black: #3f4853;
/** Green color */
$at-green: #43a047;
